<template>
  <svg
    version="1.1"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 512 512"
    style="enable-background:new 0 0 512 512;"
    xml:space="preserve"
  >
    <g>
      <polygon
        style="fill:#65B4BB;"
        points="423.511,507.152 350.913,507.152 297.69,420.59 276.599,386.286 349.185,386.286 
		370.276,420.59 	"
      />
      <polygon
        style="fill:#65B4BB;"
        points="235.396,386.286 214.305,420.59 161.082,507.152 88.497,507.152 141.707,420.59 
		162.797,386.286 	"
      />
    </g>
    <g>
      <polygon
        style="fill:#57A3A7;"
        points="162.797,386.286 235.396,386.286 214.305,420.59 141.707,420.59 	"
      />
      <polygon
        style="fill:#57A3A7;"
        points="370.276,420.59 297.69,420.59 276.599,386.286 349.185,386.286 	"
      />
    </g>
    <g>
      <rect
        x="21.247"
        y="82.564"
        style="fill:#F2F2F2;"
        width="469.513"
        height="310.074"
      />
      <rect
        x="21.247"
        y="82.564"
        style="fill:#F2F2F2;"
        width="469.513"
        height="32.399"
      />
    </g>
    <g>
      <rect
        x="125.427"
        y="116.94"
        style="fill:#FED159;"
        width="19.058"
        height="255.695"
      />
      <rect
        x="71.028"
        y="310.392"
        style="fill:#FED159;"
        width="330.402"
        height="19.058"
      />
    </g>
    <polygon
      style="fill:#F64B4A;"
      points="170.285,294.449 156.809,280.972 243.844,193.937 280.021,230.114 370.462,139.672 
	383.939,153.148 280.021,257.066 243.844,220.889 "
    />
    <polyline
      style="fill:#65B4BB;"
      points="102.943,88.923 512,88.923 512,4.848 0,4.848 0,88.923 72.45,88.923 "
    />
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
  </svg>
</template>

<script>
export default {};
</script>

<style></style>
